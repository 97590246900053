<template>
    <q-card>
        <q-table
            row-key="id"
            :rows="data"
            :columns="columns"
            :v-model:pagination="{ page: 1, rowsPerPage: 250, totalRowsNumber: data.length }"
            :rows-per-page-options="[]"
            table-header-class="d-none"
            virtual-scroll
            binary-state-sort
            flat
        >
            <template v-slot:body="props">
                <q-tr
                    :props="props"
                    class="clickable"
                    @click="handleClick(props.row)"
                >
                    <q-td
                        key="details"
                        :props="props"
                    >
                        <div class="text-h6 text-center q-pa-md shadow rounded">

                            <div >
                                 <span>
                                  {{ `${$t('Order')} #${props.row.id}` }}
                                </span>

                                <div class="text-subtitle2 text-bold">
                                    <small>{{ `${props.row._embedded.shop.name} (${props.row._embedded.shop.id})` }}</small>
                                </div>
                            </div>

                        </div>
                    </q-td>
                </q-tr>
            </template>
        </q-table>
    </q-card>
</template>

<script>
export default {
  name: 'OrderObject',
    emits: ['click'],
    props: {
        data: {
            type: Array,
            default () {
                return []
            }
        },
        rawData: {
            type: Object,
            default () {
                return {}
            }
        }
    },
    data () {
        return {
            filter: '',
            columns: [
                {
                    label: '',
                    name: 'details',
                    align: 'left'
                }
            ],
        }
    },
    methods: {
        handleClick (data) {
            const event = {
                type: this.rawData.entityClass,
                event: 'storage.order.opened',
                data
            }

            this.$emit('click', event)
        }
    }
}
</script>
